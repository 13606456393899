.notification {
  background: $primary-green;
  box-shadow: 0 0 10px 5px rgba($pale-green, $alpha: 0.5);
  width: calc(12em + 6vw);
  float: right;

  &-icon {
    color: white !important;
  }

  & .toast-header {
    color: white;
    background: inherit;
  }
}

.card {
  background: $dark-grey;
  border: none;
  border-radius: 20px;
  width: 15rem;
  box-shadow: $card-shadow $shadow-color;
  transition: 0.2s;

  &-img {
    &-top {
      width: 100%;
      height: 12rem;
      object-fit: cover;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }

  &-body {
    background: $dark-grey;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &-details {
    & .card-img-top {
      border-radius: 20px;
    }
    &:hover {
      box-shadow: $card-shadow $shadow-color !important;
    }
  }

  &:hover {
    box-shadow: $card-shadow rgba($grey, $alpha: 0.3);
  }
}

.cart {
  &-hover {
    &:hover {
      background: $orange;
    }

    &:active {
      background: $dark-orange !important;
    }
  }
}
