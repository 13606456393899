.btn {
  &-delete {
    &:hover > i {
      animation: deleteAnim 0.3s ease-in;
    }
  }
}

.cart {
  &-body {
    width: 80vw;
    height: calc(25rem + 5vw);
    overflow: auto;
    box-shadow: $container-inset-shadow $dark-shadow-color;
    border-radius: 20px;
    background: rgba($dark-pale-blue, $alpha: .3);
    backdrop-filter: blur(5px);
  }

  &-count {
    font-size: small;
    display: inline-block;
    padding-left: .5em;
    padding-right: .5em;
    margin-left: .5em;
    margin-right: .5em;
    background: $orange;
    border-radius: 20px;
  }

  &-item {
    margin-bottom: calc(.5em + 1vw);
    background: $dark-grey;
    border-radius: 20px;
    padding: 1em;
    box-shadow: $item-shadow $shadow-color;

    &-description {
      display: block;
      overflow: auto;
      height: calc(2.5em + 4vw);
    }

    &-qty {
      background: $grey;
      border-radius: 20px;
      width: 3rem;
      height: inherit;
      margin-left: .5rem;
      margin-right: .5rem;
      padding: .5rem;
      box-shadow: $button-shadow $shadow-color;
    }
  }

  &-img {
    max-width: calc(8em + 3vw);
    width: calc(8em + 3vw);
    box-shadow: none;
    margin-right: 1em;

    &-top {
      width: 100%;
      height: calc(8em + 3vw);
      object-fit: cover;
      border-radius: 20px;
    }
  }
}

.vert-line {
  display: inline-block;
  width: 1px;
  height: 140px;
  background: rgba(255, 255, 255, .2);
  box-shadow: 0 0 1px 1px rgba(255,255,255,.2);
}