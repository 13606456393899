/* General Colors */

$primary-red: #e51b23;
$primary-blue: #1c7be1;
$primary-green: #198754;


/* Colors Palette */

$orange: #f98921;
$dark-orange: #ac6018;
$pink: #e74452;
$pale-pink: #c25c64;
$pale-red: #eb4034;
$dark-red: #a01418;
$dark-blue: #1a5da3;
$dark-pale-blue: #26353e;
$grey-pale-blue: #696e71;
$dark-green: #109130;
$pale-green: #42694f;

/* Black & White Palette */

$lighter-grey: #c0c0c0;
$hover-grey: #8e8e8e;
$light-grey: #484848;
$grey: #323232;
$dark-grey: #1d1d1d;
$darker-grey: #0f0f0f;

/* Images */

$background: url("../../images/background.jpg");

/* Box Shadow */

$icon-shadow: 0 8px 10px;
$text-shadow: 0 2px 5px;
$button-shadow: 0 2px 8px 2px;
$container-inset-shadow: inset 0 0 8px 4px;
$button-inset-shadow: inset 0 0 3px 2px;
$card-inset-shadow: inset 0 0 15px 10px;
$card-shadow: 0 5px 10px 8px;
$item-shadow: 0 0 6px 3px;
$footer-shadow: 0 0 15px 10px;

$shadow-color: rgba(0,0,0,.3);
$dark-shadow-color: rgba(0,0,0,.5);