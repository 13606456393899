.profile {
  &-favorites {
    background: rgba($grey, $alpha: 0.5);
    backdrop-filter: blur(5px);
    border: 3px solid rgba($lighter-grey, $alpha: 0.5);
    height: 15em;
    overflow-y: auto;

    &-item {
      background: $grey;
      border-bottom: 1px solid rgba($lighter-grey, $alpha: 0.5);
      transition: 0.2s;
      cursor: default;

      &:hover {
        background: $light-grey;
      }
    }
  }
}
