@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Pacifico";
  src: local("Pacifico"),
    url("./assets/fonts/Pacifico/Pacifico-Regular.ttf");
}

@import "./assets/sass/abstracts/variables";
@import "./assets/sass/abstracts/mixins";

@import "./assets/sass/base/base";
@import "./assets/sass/base/animations";

@import "./assets/sass/components/layout/layout";
@import "./assets/sass/components/layout/navbar";
@import "./assets/sass/components/layout/footer";

@import "./assets/sass/components/content/home";
@import "./assets/sass/components/content/menu";
@import "./assets/sass/components/content/about";
@import "./assets/sass/components/content/cart";
@import "./assets/sass/components/content/profile";

@import "./assets/sass/components/checkout/checkout";

.form-control {
  color: #f9fafb;
  background: none;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
}

.btn-primary, .btn-success, .btn-danger, .btn-light {
  color: white;
  border-radius: 20px;
  background: #323232;
  border: 2px solid rgba(255, 255, 255, 0.5);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  // box-shadow: 0 2px 8px 2px rgb(0 0 0 / 30%);
}


.contact-map {
  border: 2px solid #fff;
  border-radius: 10px;
  position: relative;
  height: 465px;
  padding: 5px;
}

.google-map iframe {
  width: 100%;
  height: 450px;
  border: 0;
}


.social-container {
  padding: 25px 50px;
}

a.social {
  margin: 0 0.5rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  // border: 1px solid #fff;
  // border-radius: 25px;
  color: #eb3223;
  padding: 10px;
}

a.facebook {
  // border: 1px solid #fff;
  // border-radius: 25px;
  color: #4968ad;
  padding: 10px;
}

a.twitter {
  // border: 1px solid #fff;
  // border-radius: 25px;
  color: #49a1eb;
  padding: 10px;

}

a.instagram {
  // border: 1px solid #fff;
  // border-radius: 25px;
  color:#da3f99 ;
  padding: 10px;
}

.svg-inline--fa.fa-w-14 {
  width: 0.995em;
}

.flex {
  display: flex;
}