.container {
  padding: 2em;
  background: none;
}

.form-control {
  color: white;
  background: none;
  border: none;
  border-bottom: 2px solid rgba(white, $alpha: .5);
  border-radius: 0;

  &:focus {
    color: white;
    background: none;
    box-shadow: none;
    border-color: white;
    box-shadow: 0 1px 0 0 white;
  }

  &:disabled {
    background: inherit;
    color: rgba(white, $alpha: .5);
  }
}

.btn {
  &.cart {
    border-radius: 10px;

    &-hover {
      border-radius: 10px;
    }
  }

  &-primary,
  &-success,
  &-danger,
  &-light {
    color: white;
    border-radius: 20px;
    background: $grey;
    border: none;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: .6em;
    padding-bottom: .6em;
    box-shadow: $button-shadow $shadow-color;
    
    &:disabled {
      background-color: rgba($grey, $alpha: .9);
    }

    &:active:focus {
      color: $lighter-grey;
      background: $dark-blue;
      box-shadow: $button-inset-shadow $dark-shadow-color;
    }
    
    &:active,
    &:focus {
      color: white;
      border-color: none;
      box-shadow: $button-shadow $shadow-color;
      background: $grey;
    }

    &:hover {
      color: white;
      background: $primary-blue;
      outline: none;
      border: none;
      box-shadow: $button-shadow $shadow-color;
    }
  }

  &-success {
    &:active:focus { background: $dark-green; }
    &:hover { background: $primary-green; }
  }

  &-danger {
    &:active:focus { background: $dark-red; }
    &:hover { background: $primary-red; }
  }

  &-light {
      padding-top: .5em;
      padding-bottom: .5em;
      padding-left: 1em;
      padding-right: 1em;

    &:active { background: $grey !important; }
    &:hover { background: $light-grey; }

    &.active {
      &:active:focus { background: $lighter-grey; }
      &:focus { box-shadow: none; }
    }
  }

  &-like {
    &:disabled {
      box-shadow: $button-inset-shadow $darker-grey;
    }
  }
}

.error {
  &-message {
    display: inline-block;
    justify-content: center;
    width: 100%;
    color: $primary-red;
  }

  &-icon {
    font-size: 1em;
    color: $primary-red;
  }
}

.info {
  &-icon {
    font-size: 1em;
    color: $primary-blue;
  }
}

.spinner {
  &-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.modal {
  &-content {
    background: $dark-grey;
  }

  &-header, &-footer {
    border: none;
  }
}

.close {
  display: none;
}

.fas.fa {
  &-heart {
    color: $primary-red;
    transition: .2s;
    
    &:hover {
      color: rgba($dark-red, $alpha: .8);
      cursor: pointer;
    }
  }
}

.not-found {
  color: rgba(255, 255, 255, .3);
}